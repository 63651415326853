[class*='grid'] {
  @include grid-container;
}

// Grid
.grid {

  // Horizontal Gutter
  &-hg {

    &-collapse {
      margin-left: -20px;
      margin-right: -20px;

      &\@m {
        @include grid-media($grid-md) {
          &-rs {
            margin-left: 0;
            margin-right: 0;
          }
        }
      }

      &\@s {
        @include grid-media($grid-sm) {
          &-rs {
            margin-left: 0;
            margin-right: 0;
          }
        }
      }
    }

    & > [class*='col'] {
      padding-left: 20px;
      padding-right: 20px;

      @include grid-media($grid-md) {
        padding-left: 10px;
        padding-right: 10px;
      }
    }

    &-fullwidth {
      & > :first-child {
        padding-left: 0;
      }
      & > :last-child {
        padding-right: 0;
      }
    }

    &\@m {
      @include grid-media($grid-md) {
        &-rs {

          & > [class*='col'] {
            padding-left: 0;
            padding-right: 0;
          }
        }
      }
    }

    &\@s {
      @include grid-media($grid-sm) {
        &-rs {

          & > [class*='col'] {
            padding-left: 0;
            padding-right: 0;
          }
        }
      }
    }
  }

  // Vertical Gutter
  &-vg {

    & > [class*='col'] {
      margin-top: 20px;
      margin-bottom: 20px;

      @include grid-media($grid-md) {
        margin-top: 10px;
        margin-bottom: 10px;
      }
    }
  }

  // Margin
  &-m {
    margin: 20px;

    &h {
      margin-left: 20px;
      margin-right: 20px;
    }

    &v {
      margin-top: 20px;
      margin-bottom: 20px;
    }

    &\@m {
      @include grid-media($grid-md) {
        margin: 20px;

        &-v {
          margin-top: 20px;
          margin-bottom: 20px;

          &-rs {
            margin-top: 0;
            margin-bottom: 0;
          }
        }

        &-h {
          margin-left: 20px;
          margin-right: 20px;

          &-rs {
            margin-left: 0;
            margin-right: 0;
          }
        }

        &-rs {
          margin: 0;
        }

        &-rsh {
          margin: 20px 0;
        }
      }
    }
  }

  // Padding {
  &-p {
    padding: 20px;

    &h {
      padding: 0 20px;
    }

    &v {
      padding: 20px 0;
    }
  }

  // Max Width
  &-mw {

    &--1380 {
      max-width: 1380px;
      margin: 0 auto;
    }

    &--1380-flex {
      max-width: 1380px;
      margin: 0 auto;
      display: flex;
      align-items: center;
      justify-content: center;
    }

    &--1200{
      max-width: 1200px;
      margin: 0 auto;
    }

    &--1080 {
      max-width: 1080px;
      margin: 0 auto;
    }

    &--750 {
      max-width: 750px;
      margin: 0 auto;
    }

    @media (max-width: 64em){
      &--1380-flex {
        display: block;
        text-align: center;
      }
    }
  }



  & > {
    // 1 / 12 Columns
    .col-1 {
      @include grid-column(1, $grid-lg);
    }
    // 2 / 12 Columns
    .col-2 {
      @include grid-column(2, $grid-lg);
    }
    // 3 / 12 Columns
    .col-3 {
      @include grid-column(3, $grid-lg);
    }
    // 4 / 12 Columns
    .col-4 {
      @include grid-column(4, $grid-lg);
    }
    // 5 / 12 Columns
    .col-5 {
      @include grid-column(5, $grid-lg);
    }
    // 6 / 12 Columns
    .col-6 {
      @include grid-column(6, $grid-lg);
    }
    // 7 / 12 Columns
    .col-7 {
      @include grid-column(7, $grid-lg);
    }
    // 8 / 12 Columns
    .col-8 {
      @include grid-column(8, $grid-lg);
    }
    // 9 / 12 Columns
    .col-9 {
      @include grid-column(9, $grid-lg);
    }
    // 10 / 12 Columns
    .col-10 {
      @include grid-column(10, $grid-lg);
    }
    // 11 / 12 Columns
    .col-11 {
      @include grid-column(11, $grid-lg);
    }
    // 12 / 12 Columns
    .col {
      @include grid-column(12, $grid-lg);
    }
}

  // 6 / 12 Columns
  &-2 > {
    .col {
      @include grid-column(6, $grid-lg);
    }
  }

  // 3 / 12 Columns
  &-3 > {
    .col {
      @include grid-column(4, $grid-lg);

      &-1 {
        @include grid-column(4, $grid-lg);
      }

      &-2 {
        @include grid-column(8, $grid-lg);
      }
    }
  }

  // 4 / 12 Columns
  &-4 > {
    .col {
      @include grid-column(3, $grid-lg);
    }
  }

  // 1 / 5 Columns
  &-5 > {
    .col {
      @include grid-column(1, $grid-lg-5);
    }
  }

  // Breakpoint Large
  &\@l {
    @include grid-media($grid-lg) {
      & > .col {
        @include grid-column(12);
      }

      &-2 > {
        .col {
          @include grid-column(6);
        }
      }

      &-3 > {
        .col {
          @include grid-column(4);
        }
      }

      &-4 > {
        .col {
          @include grid-column(3);
        }
      }
    }
  }
  // Breakpoint Medium
  &\@m {
    @include grid-media($grid-md) {
      & > .col {
        @include grid-column(8);
      }

      &-2 > {
        .col {
          @include grid-column(4);
        }
      }

      &-4 > {
        .col {
          @include grid-column(2);
        }
      }
    }
  }
  // Breakpoint Medium Small
  &\@ms {
    @include grid-media($grid-ms) {
      & > .col {
        @include grid-column(6);
      }

      &-2 > {
        .col {
          @include grid-column(3);
        }
      }

      &-3 > {
        .col {
          @include grid-column(2);
        }
      }
    }
  }
  // Breakpoint Small
  &\@s {
    @include grid-media($grid-sm) {
      & > .col {
        @include grid-column(4);
      }

      &-2 > {
        .col {
          @include grid-column(2);
        }
      }

      &-4 > {
        .col {
          @include grid-column(1);
        }
      }
    }
  }

  // Columns
  .col {

    // ExtaaLarge Breakpoint
    &\@xl {
      @include grid-media($grid-xl) {
        &-3 {
          @include grid-column(3);
        }
        &-9 {
          @include grid-column(9);
        }
      }
    }

    // Large Breakpoint
    &\@l {
      @include grid-media($grid-lg) {
        &-4 {
          @include grid-column(4);
        }
        &-8 {
          @include grid-column(8);
        }
      }
    }

    // Large Min Breakpoint
    &\@l-min {
      @include grid-media($grid-lg-min) {
        &-2 {
          @include grid-column(2);
        }
        &-10 {
          @include grid-column(10);
        }
      }
    }

    // Medium Large Breakpoint
    &\@ml {
      @include grid-media($grid-ml) {
        @include grid-column(12);

        &-3 {
          @include grid-column(3);
        }
        &-9 {
          @include grid-column(9);
        }
      }
    }

    // Medium Breakpoint
    &\@m {
      @include grid-media($grid-md) {
        &-4 {
          @include grid-column(4);
        }
        &-8 {
          @include grid-column(8);
        }
      }
    }

    // Medium Small Breakpoint
    &\@ms {
      @include grid-media($grid-ms) {
        &-3 {
          @include grid-column(3);
        }
        &-6 {
          @include grid-column(6);
        }
      }
    }

    // Small Breakpoint
    &\@s {
      @include grid-media($grid-sm) {
        &-2 {
          @include grid-column(2);
        }
        &-4 {
          @include grid-column(4);
        }
      }
    }
  }
}
